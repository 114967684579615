import React, { useEffect } from 'react'
import Router from 'next/router'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import localFont from 'next/font/local'
import { GoogleTagManager } from '@next/third-parties/google'
import { LazyMotion, domAnimation, AnimatePresence } from 'framer-motion'

import '../styles/tailwind.css'
import '../styles/app.css'

import { isBrowser, isUSStore, useScrollRestoration } from '@lib/helpers'
import { pageTransitionSpeed } from '@lib/animate'

import {
  SiteContextProvider,
  useSiteContext,
  useTogglePageTransition,
} from '@lib/context'

const Cart = dynamic(() => import('@components/overlays/cart'))
const Wishlist = dynamic(() => import('@components/overlays/wishlist'))
const Frames = dynamic(() => import('@components/frames'))
const Story = dynamic(() => import('@components/story'))
const Page = dynamic(() => import('@components/overlays/page'))

// import Cart from '@components/overlays/cart'
// import Wishlist from '@components/overlays/wishlist'
// import Frames from '@components/frames'
// import Story from '@components/story'
// import Page from '@components/overlays/page'

// Console Credits
if (isBrowser) {
  console.groupCollapsed(
    '%c💀 Site Credits',
    'display:block;padding:0.125em 1em;font-family:courier;font-size:14px;font-weight:bold;line-height:2;text-transform:uppercase;background:black;color:white;'
  )
  console.log(
    '%cDevelopment by Daniel J. Mierzwinski \n– https://www.dm-studio.dk',
    'display:block;font-family:courier;font-size:12px;font-weight:bold;line-height:1;color:black;'
  )
  console.groupEnd()
}

const neueHaasGroteskText = localFont({
  src: [
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-55Roman.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-56Italic.otf',
      weight: '400',
      style: 'italic',
    },
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-65Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-66MediumItalic.otf',
      weight: '500',
      style: 'italic',
    },
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-75Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: './fonts/neue_haas_grotesk_text/NeueHaasGrotText-76BoldItalic.otf',
      weight: '700',
      style: 'italic',
    },
  ],
  variable: '--font-neue-haas-grotesk-text',
})

const Site = ({ Component, pageProps, router }) => {
  const togglePageTransition = useTogglePageTransition()
  // Try to limit the gigantic DOM size by only including when used
  const { isPageTransition, isCartOpen, isFramesOpen, isWishlistOpen } =
    useSiteContext()

  const { data } = pageProps

  // Handle scroll position on history change
  useScrollRestoration(router, pageTransitionSpeed)

  // Trigger our loading class
  useEffect(() => {
    if (isBrowser) {
      document.documentElement.classList.toggle('is-loading', isPageTransition)
    }
  }, [isPageTransition])

  // Setup page transition loading states
  useEffect(() => {
    Router.events.on('routeChangeStart', (_, { shallow }) => {
      // Bail if we're just changing URL parameters
      if (shallow) return

      // Otherwise, start loading
      togglePageTransition(true)
    })

    Router.events.on('routeChangeComplete', () => {
      setTimeout(() => togglePageTransition(false), pageTransitionSpeed)
    })

    Router.events.on('routeChangeError', () => {
      togglePageTransition(false)
    })
  }, [])

  // intelligently add focus states if keyboard is used
  const handleFirstTab = (event) => {
    if (event.keyCode === 9) {
      if (isBrowser) {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleFirstTab)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab)
    return () => {
      window.removeEventListener('keydown', handleFirstTab)
    }
  }, [])

  // Redirect to US store, while it is being built
  if (isUSStore() && typeof window !== 'undefined')
    return (window.location.href = 'https://papercollective.com')

  return (
    <LazyMotion features={domAnimation}>
      {isPageTransition && (
        <Head>
          <title>Loading...</title>
        </Head>
      )}
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          document.body.classList.remove('overflow-hidden')
        }}
      >
        <Component key={router.asPath.split('?')[0]} {...pageProps} />
      </AnimatePresence>

      {/* Overlays */}
      {Boolean(isCartOpen && !isFramesOpen) && (
        <Cart data={{ ...data?.site }} />
      )}
      <Frames
        data={{ ...data?.page }}
        framePickerTitle={data?.site?.shop?.framePickerTitle}
        framePickerDiscountCode={data?.site?.shop?.framePickerDiscountCode}
        framePickerDiscountPercentage={
          data?.site?.shop?.framePickerDiscountPercentage
        }
      />
      <Page data={{ ...data?.site }} />
      <Story />
      {isWishlistOpen && <Wishlist />}
    </LazyMotion>
  )
}

// Site wrapped with Context Providers
const MyApp = ({ Component, pageProps, router }) => {
  const { data } = pageProps

  return (
    <main className={`${neueHaasGroteskText.variable} font-sans`}>
      <Script
        id="CookieConsent"
        src="https://policy.app.cookieinformation.com/uc.js"
        data-culture="EN"
        type="text/javascript"
        strategy="lazyOnload"
      />
      <Script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VnNwa2" />
      {data?.site?.gtmID && <GoogleTagManager gtmId={data?.site?.gtmID} />}
      <SiteContextProvider data={{ ...data?.site }}>
        <Site Component={Component} pageProps={pageProps} router={router} />
      </SiteContextProvider>
    </main>
  )
}

export default MyApp
