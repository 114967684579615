import { sendGTMEvent } from '@next/third-parties/google'

export function track(eventName, payload = {}) {
  sendGTMEvent({
    event: eventName,
    ...payload,
  })
}

const currency = 'EUR'

const prepare = (product) => {
  return {
    ...product,
    ID: `${product?.sku}_${product?.productID}`,
    currency,
  }
}

export function trackProductViewed(product) {
  track('product_viewed', prepare(product?.gtmData))
}

export function trackAddToCart(product) {
  track('product_added', prepare(product?.gtmData))
}

export function trackInitiateCheckout(products, totalValue) {
  track('checkout_initiated', {
    totalValue,
    currency,
    products: products.map((product) => prepare(product?.gtmData)),
  })
}
