import React, { createContext, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Base64 } from 'base64-string'

// get our API clients (shopify + sanity)
import { getSanityClient } from '@lib/sanity'
import shopify from '@lib/shopify'

// get our global image GROQ
import { trackAddToCart } from './gtm'
import {
  currencies,
  defaultCurrency,
  getCurrency,
  isBrowser,
  isPrimaryStore,
  isUSStore,
} from './helpers'

function getWishlist() {
  if (!isBrowser) return []
  return JSON.parse(localStorage.getItem('wishlist')) || []
}

// Set our initial context states
const initialContext = {
  isPageTransition: false,
  shopifyClient: shopify,
  isLoading: true,
  isAdding: false,
  isUpdating: false,
  isCartOpen: false,
  isWishlistOpen: false,
  isFiltersOpen: false,
  isFramesOpen: false,
  isPageOverlayOpen: false,
  lastAddedPoster: null,
  itemsAdded: null,
  wishlist: getWishlist(),
  exchangedPrices: [],
  currentCurrency: null,
  checkout: {
    id: null,
    lineItems: [],
  },
}

// set Shopify variables
const SHOPIFY_CHECKOUT_ID = 'shopify_checkout_id_v2'
const SHOPIFY_VARIANT_GID = 'gid://shopify/ProductVariant/'
const CURRENCY_CODE = 'currencyCode_v2'

// Set context
const SiteContext = createContext({
  context: initialContext,
  setContext: () => null,
})

// Build a new checkout
const createNewCheckout = ({ shopifyClient }) => {
  return shopifyClient?.checkout.create({
    presentmentCurrencyCode: localStorage.getItem(CURRENCY_CODE) || 'EUR',
  })
}

// Get Shopify checkout cart
const fetchCheckout = (context, id) => {
  return context.shopifyClient?.checkout.fetch(id)
}

// get associated variant from Sanity
const fetchVariant = async (id) => {
  const variant = await getSanityClient().fetch(
    `
      *[_type == "productVariant" && variantID == ${id}][0]{
        "product": *[_type == "product" && productID == ^.productID][0]{
          title,
          "type": _type,
          "slug": slug.current,
          "sku": ^.sku,
          "photo": shopifyImageSrc,
          category->{
            "slug": slug.current,
            suggestFrames,
          },
          artist->{
            "type": _type,
            title,
            "slug": slug.current,
          },
        },
        "id": variantID,
        title,
        price,
        "photo": shopifyImageSrc,
        "gtmData": {
          "id": variantID,
          productID,
          sku,
          "title": *[_type == "product" && productID == ^.productID][0].title,
          "variant": variantTitle,
          "type": *[_type == "product" && productID == ^.productID][0].category->slug.current,
          "value": price,
        },
        options[]{
          name,
          position,
          value
        }
      }
    `
  )

  return variant
}

// set our checkout states
const setCheckoutState = async (
  checkout,
  setContext,
  openCart,
  adding = false
) => {
  if (!checkout) return null

  if (typeof window !== `undefined`) {
    localStorage.setItem(SHOPIFY_CHECKOUT_ID, checkout.id)
  }

  // get real lineItems data from Sanity
  const lineItems = await Promise.all(
    checkout.lineItems.map(async (item) => {
      const variantID = item.variant.id.split(SHOPIFY_VARIANT_GID)[1]
      const variant = await fetchVariant(variantID)

      return { ...variant, quantity: item.quantity, lineID: item.id }
    })
  )

  // update state
  setContext((prevState) => {
    return {
      ...prevState,
      isAdding: false,
      isLoading: false,
      isUpdating: false,
      isCartOpen: openCart ? true : prevState.isCartOpen,
      itemsAdded: adding && (prevState.itemsAdded || 0) + 1,
      checkout: {
        id: checkout.id,
        lineItems: lineItems,
        subTotal: checkout.lineItemsSubtotalPrice,
        webUrl: checkout.webUrl,
      },
    }
  })
}

/*  ------------------------------ */
/*  Our Context Wrapper
/*  ------------------------------ */

const SiteContextProvider = ({ data, children }) => {
  const [context, setContext] = useState({
    ...initialContext,
  })

  const { currentCurrency, shopifyClient, exchangedPrices } = context

  const setCurrency = useSetCurrency(setContext)

  const [initContext, setInitContext] = useState(false)

  useEffect(() => {
    async function initialize() {
      // Shopify checkout not build yet
      if (initContext === false) {
        const initializeCheckout = async () => {
          const existingCheckoutID =
            typeof window !== 'undefined'
              ? localStorage.getItem(SHOPIFY_CHECKOUT_ID)
              : false

          // existing Shopify checkout ID found
          if (existingCheckoutID) {
            try {
              // fetch checkout from Shopify
              const existingCheckout = await fetchCheckout(
                context,
                existingCheckoutID
              )

              // Check if there are invalid items
              if (
                existingCheckout.lineItems.some((lineItem) => !lineItem.variant)
              ) {
                throw new Error(
                  'Invalid item in checkout. This variant was probably deleted from Shopify.'
                )
              }

              // Make sure this cart hasn’t already been purchased.
              if (!existingCheckout.completedAt) {
                setCheckoutState(existingCheckout, setContext)
                return
              }
            } catch (e) {
              localStorage.setItem(SHOPIFY_CHECKOUT_ID, null)
            }
          }

          // Otherwise, create a new checkout!
          const newCheckout = await createNewCheckout(context)
          setCheckoutState(newCheckout, setContext)
        }

        const initializeCurrency = async () => {
          const currencyCode = isPrimaryStore()
            ? localStorage.getItem(CURRENCY_CODE)
            : 'USD'

          let data
          if (!currencyCode) {
            try {
              const res = await fetch(
                `https://api.ipregistry.co/?key=${process.env.IPREGISTRY_API_KEY}&fields=currency`
              )
              if (res.ok) {
                data = await res.json()
              }
            } catch (e) {
              console.log(e)
            }
          }
          setCurrency(currencyCode || data?.currency?.code || 'EUR')
        }

        // Initialize the store context
        await initializeCurrency()
        initializeCheckout()
        setInitContext(true)
      }
    }

    initialize()
  }, [initContext, context, setContext, shopifyClient?.checkout])

  useEffect(() => {
    function fetchProducts(after) {
      axios({
        url: `https://${process.env.SHOPIFY_STORE_ID}.myshopify.com/api/2022-10/graphql.json`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_API_TOKEN,
        },
        data: {
          query: `
            query allProducts($after: String${
              currentCurrency?.countryCode !== defaultCurrency.countryCode
                ? ', $countryCode: CountryCode) @inContext(country: $countryCode'
                : ''
            }) {
              products (first: 250, after: $after) {
                edges {
                  cursor
                  node {
                    variants(first: 250) {
                      edges {
                        node {
                          sku
                          compareAtPriceV2{
                            amount
                          }
                          priceV2 {
                            amount
                            currencyCode
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            `,
          variables: {
            countryCode: currentCurrency.countryCode,
            after,
          },
        },
      }).then((result) => {
        const { data: { data = {} } = {} } = result
        const { products: { edges: products = [] } = {} } = data || {}
        if (products?.length > 0) {
          // Store exchanged product prices in memory
          setContext((prevState) => {
            const prices = [
              ...prevState.exchangedPrices,
              ...products
                .map((product) =>
                  product.node.variants.edges.map(({ node: variant }) => {
                    return {
                      sku: variant.sku,
                      price: parseInt(variant.priceV2.amount, 10),
                      comparePrice:
                        variant.compareAtPriceV2 &&
                        parseInt(variant.compareAtPriceV2.amount, 10),
                      currencyCode: variant.priceV2.currencyCode,
                    }
                  })
                )
                .flat(),
            ]
            return {
              ...prevState,
              exchangedPrices: prices,
            }
          })

          // Fetch next 250 products, until empty
          const { cursor } = products[products.length - 1]
          if (products.length === 250) {
            fetchProducts(cursor)
          }
        }
      })
    }

    // Fetch exchanged prices from Shopify
    // Check if exchange prices has previously been fetched
    const isExchangedPriceFetched = exchangedPrices.some(
      (p) => p.currencyCode === currentCurrency.currencyCode
    )
    if (!isExchangedPriceFetched && currentCurrency) fetchProducts()
  }, [currentCurrency])

  return (
    <SiteContext.Provider
      value={{
        context,
        setContext,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

// Access our global store states
function useSiteContext() {
  const { context } = useContext(SiteContext)
  return context
}

// Toggle page transition state
function useTogglePageTransition() {
  const { setContext } = useContext(SiteContext)

  async function togglePageTransition(state) {
    setContext((prevState) => {
      return { ...prevState, isPageTransition: state }
    })
  }
  return togglePageTransition
}

/*  ------------------------------ */
/*  Our Shopify context helpers
/*  ------------------------------ */

// Access our cart item count
function useCartCount() {
  const {
    context: { checkout },
  } = useContext(SiteContext)

  let count = 0

  if (checkout.lineItems) {
    count = checkout.lineItems.reduce((total, item) => item.quantity + total, 0)
  }

  return count
}

// Access our cart totals
function useCartTotals() {
  const {
    context: { checkout },
  } = useContext(SiteContext)

  const subTotal = checkout.subTotal ? checkout.subTotal.amount * 100 : false
  return {
    subTotal,
  }
}

// Access our cart items
function useCartItems() {
  const {
    context: { checkout },
  } = useContext(SiteContext)

  return checkout.lineItems
}

// Add an item to the checkout cart
function useAddItem() {
  const {
    context: { checkout, shopifyClient },
    setContext,
  } = useContext(SiteContext)

  async function addItem(product, quantity, attributes, discountCode) {
    // Bail if no ID or quantity given
    if (!product?.id || !quantity) return

    // Otherwise, start adding the product
    setContext((prevState) => {
      return {
        ...prevState,
        ...(product?.category?.suggestFrames
          ? { isCartOpen: true, lastAddedPoster: product }
          : { isCartOpen: true }),
        isAdding: true,
        isUpdating: true,
      }
    })

    // build encoded variantID
    const enc = new Base64()
    const variant = enc.urlEncode(`${SHOPIFY_VARIANT_GID}${product.id}`)

    // Build the cart line item
    const newItem = {
      variantId: variant,
      quantity: quantity,
      customAttributes: attributes,
    }

    trackAddToCart(product)

    // Add it to the Shopify checkout cart
    let newCheckout = await shopifyClient.checkout.addLineItems(
      checkout.id,
      newItem
    )

    if (discountCode) {
      newCheckout = await shopifyClient.checkout.addDiscount(
        newCheckout.id,
        discountCode
      )
    }

    // Update our global store states
    setCheckoutState(newCheckout, setContext, false, true)
  }

  return addItem
}

// Add an item to the checkout cart
function useResetItemsAdded() {
  const { setContext } = useContext(SiteContext)

  async function resetItemsAdded() {
    setContext((prevState) => {
      return {
        ...prevState,
        itemsAdded: null,
      }
    })
  }

  return resetItemsAdded
}

// Update item in cart
function useUpdateItem() {
  const {
    context: { checkout, shopifyClient },
    setContext,
  } = useContext(SiteContext)

  async function updateItem(itemID, quantity) {
    // Bail if no ID or quantity given
    if (!itemID || !quantity) return

    // Otherwise, start adding the product
    setContext((prevState) => {
      return { ...prevState, isUpdating: true }
    })

    const newCheckout = await shopifyClient.checkout.updateLineItems(
      checkout.id,
      [{ id: itemID, quantity: quantity }]
    )

    setCheckoutState(newCheckout, setContext)
  }
  return updateItem
}

// Remove item from cart
function useRemoveItem() {
  const {
    context: { checkout, shopifyClient },
    setContext,
  } = useContext(SiteContext)

  async function removeItem(itemID) {
    // Bail if no ID given
    if (!itemID) return

    // Otherwise, start removing the product
    setContext((prevState) => {
      return { ...prevState, isUpdating: true }
    })

    const newCheckout = await shopifyClient.checkout.removeLineItems(
      checkout.id,
      [itemID]
    )

    setCheckoutState(newCheckout, setContext)
  }
  return removeItem
}

// Build our Checkout URL
function useCheckout() {
  const {
    context: { checkout },
  } = useContext(SiteContext)

  return checkout.webUrl
}

// Add an item to the checkout cart
function useUpdateWishlist() {
  const { setContext } = useContext(SiteContext)

  async function updateItems() {
    // Otherwise, start adding the product
    setContext((prevState) => ({
      ...prevState,
      wishlist: getWishlist(),
    }))
  }

  return updateItems
}

function useAddWishlistItem() {
  const { setContext } = useContext(SiteContext)

  async function addItem(pId) {
    // Bail if no ID or quantity given
    if (!pId) return
    setContext((prevState) => ({
      ...prevState,
      wishlist: [...prevState.wishlist, pId],
    }))
  }

  return addItem
}

// Add an item to the checkout cart
function useRemoveWishlistItem() {
  const { setContext } = useContext(SiteContext)

  async function removeItem(pId) {
    // Bail if no ID or quantity given
    if (!pId) return
    setContext((prevState) => ({
      ...prevState,
      wishlist: prevState.wishlist.filter((id) => id !== pId),
    }))
  }

  return removeItem
}

// Toggle overlays
function useToggleCart() {
  const {
    context: { isCartOpen },
    setContext,
  } = useContext(SiteContext)

  async function toggleCart(open) {
    setContext((prevState) => {
      return {
        ...prevState,
        isCartOpen: open !== undefined ? open : !isCartOpen,
      }
    })
  }
  return toggleCart
}

function useToggleWishlist() {
  const {
    context: { isWishlistOpen, wishlist },
    setContext,
  } = useContext(SiteContext)

  async function toggleWishlist(open) {
    setContext((prevState) => {
      return {
        ...prevState,
        isWishlistOpen: open !== undefined ? open : !isWishlistOpen,
      }
    })
  }
  return { toggleWishlist, wishlist }
}

function useTogglePageOverlay() {
  const {
    context: { isPageOverlayOpen },
    setContext,
  } = useContext(SiteContext)

  async function togglePageOverlay(open) {
    setContext((prevState) => {
      return {
        ...prevState,
        isPageOverlayOpen: open !== undefined ? open : !isPageOverlayOpen,
      }
    })
  }
  return togglePageOverlay
}

// Toggle filters state
function useToggleFilters() {
  const {
    context: { isFiltersOpen },
    setContext,
  } = useContext(SiteContext)

  async function toggleFilters(open) {
    setContext((prevState) => {
      return {
        ...prevState,
        isFiltersOpen: open !== undefined ? open : !isFiltersOpen,
      }
    })
  }
  return toggleFilters
}

// Toggle frames state
function useToggleFrames() {
  const {
    context: { isFramesOpen },
    setContext,
  } = useContext(SiteContext)

  async function toggleFrames(open) {
    setContext((prevState) => {
      const _isFramesOpen = open !== undefined ? open : !isFramesOpen
      return {
        ...prevState,
        isFramesOpen: _isFramesOpen,
        ...(!_isFramesOpen ? { lastAddedPoster: null } : {}),
      }
    })
  }
  return toggleFrames
}

function convertToPrice(price, currency, round) {
  if (currency?.addVAT && !isUSStore()) price = price * 1.25
  if (round) price = Math.round(price)
  return `${currency?.prefix}${price.toLocaleString(navigator.language, {
    minimumFractionDigits: 2,
  })}${currency?.suffix}`
}

function useExchangePrice(
  // Discount percentage for frame picker, should only passed in this param when the product is a frame
  framePickerDiscountPercentage
) {
  const {
    context: { exchangedPrices, currentCurrency },
  } = useContext(SiteContext)

  function exchangePrice({ item, comparePrice: compare, quantity }) {
    const product =
      exchangedPrices.find(
        (p) =>
          p.sku === item?.sku && p.currencyCode === currentCurrency.currencyCode
      ) || {}
    const { price, comparePrice, currencyCode } = product
    const currency = getCurrency(currencyCode)

    if (price) {
      return convertToPrice(
        (compare ? comparePrice : price) *
          (framePickerDiscountPercentage !== undefined
            ? 1 - framePickerDiscountPercentage
            : 1) *
          (quantity || 1),
        currency
      )
    }
    return '...'
  }

  return exchangePrice
}

function useExchangeTotalPrice() {
  const {
    context: { exchangedPrices, currentCurrency },
  } = useContext(SiteContext)

  function exchangeTotalPrice(items) {
    const totalPrice = items.reduce((total = 0, item) => {
      const { product } = item
      const { price } =
        exchangedPrices.find(
          (p) =>
            p.sku === product?.sku &&
            p.currencyCode === currentCurrency.currencyCode
        ) || {}
      return total + (price * item.quantity || 1)
    }, 0)

    return convertToPrice(totalPrice, currentCurrency, true)
  }

  return exchangeTotalPrice
}

function useSetCurrency(_setContext) {
  const { setContext } = useContext(SiteContext)
  const sc = _setContext || setContext

  function setCurrency(currencyCode) {
    const currentCurrency =
      currencies.find((c) => c.currencyCode === currencyCode) || defaultCurrency
    localStorage.setItem(CURRENCY_CODE, currencyCode)
    sc((prevState) => ({
      ...prevState,
      currentCurrency,
    }))
  }

  return setCurrency
}

export {
  SiteContextProvider,
  useSiteContext,
  useTogglePageTransition,
  useCartCount,
  useCartTotals,
  useCartItems,
  useAddItem,
  useUpdateItem,
  useRemoveItem,
  useResetItemsAdded,
  getWishlist,
  useUpdateWishlist,
  useAddWishlistItem,
  useRemoveWishlistItem,
  useToggleFilters,
  useCheckout,
  useToggleFrames,
  useToggleCart,
  useToggleWishlist,
  useTogglePageOverlay,
  useExchangePrice,
  useExchangeTotalPrice,
  useSetCurrency,
}
