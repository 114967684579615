// @ts-ignore
import Client from 'shopify-buy'
import { isBrowser } from '@lib/helpers'
import axios from 'axios'
// First, check that Shopify variables are set
const hasShopify = process.env.SHOPIFY_STORE_ID && process.env.SHOPIFY_API_TOKEN

// Warn the client if variables are missing
if (!hasShopify && isBrowser) {
  console.warn('Shopify .env variables are missing')
}

// Otherwise, setup the client and export
const options = {
  domain: `${process.env.SHOPIFY_STORE_ID}.myshopify.com`,
  storefrontAccessToken: process.env.SHOPIFY_API_TOKEN,
}

export default hasShopify ? Client.buildClient(options) : null

export const getDiscountPercentage = async (discountCode) => {
  // Setup our Shopify connection
  const shopifyConfig = {
    'Content-Type': 'application/json',
    'X-Shopify-Access-Token': process.env.SHOPIFY_API_PASSWORD,
  }

  // Fetch our discount code from Shopify
  const percentage = await axios({
    url: `https://${process.env.SHOPIFY_STORE_ID}.myshopify.com/admin/api/2022-10/graphql.json`,
    method: 'POST',
    headers: shopifyConfig,
    data: JSON.stringify({
      query: `query codeDiscountNodeByCode($code: String!) {
        codeDiscountNodeByCode(code: $code) {
          codeDiscount {
            ... on DiscountCodeBasic {
              customerGets {
                value {
                  ... on DiscountPercentage {
                    percentage
                  }
                }
              }
            }
          }
          id
        }
      }`,
      variables: {
        code: discountCode,
      },
    }),
  })
    .then((response) => {
      const percentage =
        response.data.data.codeDiscountNodeByCode.codeDiscount.customerGets
          .value.percentage
      return percentage ?? undefined
    })
    .catch(() => {
      return undefined
    })

  return percentage
}
