import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { imageBuilder } from '@lib/sanity'
import Router, { useRouter } from 'next/router'
import queryString from 'query-string'

/*  ------------------------------ */
/*  Generic helper functions
/*  ------------------------------ */

// reference a previous state after update
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// client-side mount
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

// autoplay looper
export function useAutoplay(callback, delay) {
  const [isRunning, setIsRunning] = useState(false)
  const stop = useCallback(() => setIsRunning(false), [setIsRunning])
  const play = useCallback(() => setIsRunning(true), [setIsRunning])
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!isRunning) return
    let id = 0

    const tick = () => {
      if (!isRunning) return clearTimeout(id)
      savedCallback.current()
      requestAnimationFrame(() => (id = setTimeout(tick, delay)))
    }
    requestAnimationFrame(() => (id = setTimeout(tick, delay)))

    return () => {
      if (id) clearTimeout(id)
      stop()
    }
  }, [isRunning, delay, stop])

  return { play, stop }
}

// filter products
export function useFilterProducts({ products, filters, search, style }) {
  let filteredProducts = products
    .filter((p) => {
      const searchStrings = [
        p.title.toLowerCase(),
        p.artist?.title.toLowerCase(),
        p.category.title.toLowerCase(),
        ...(p.colors ? p.colors.map((c) => c?.title.toLowerCase()) : []),
        ...(p.styles ? p.styles.map((s) => s?.title.toLowerCase()) : []),
        ...(p.options
          ? p.options.map((o) => o.values.map((v) => v.toLowerCase()))
          : []),
      ]
      if (search)
        return searchStrings.some(
          (s) => s && s.indexOf(search.toLowerCase()) > -1
        )
      return true
    })
    .filter((p) => {
      const productFilters = {
        styles: (p.styles || [])?.map((s) => s?.slug),
        colors: (p.colors || [])?.map((c) => c?.slug),
        artists: p.artist ? [p.artist.slug] : [],
        sizes: p.options
          .filter((o) => o.name === 'Size')
          .map((s) => s.values.map((v) => v.split(' ')[0]))
          .flat(),
      }

      return filters.every((f) => {
        if (f.values.length === 0) return true
        return f.values.every((v) => productFilters[f.name].indexOf(v) > -1)
      })
    })
    .filter((p) => {
      if (!style) return true
      if (!p?.styles) return false
      return p?.styles.some((s) => s?.slug === style?.slug)
    })

  return filteredProducts
}

// conditionally wrap a component with another
export const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

// simple debounce function
export function debounce(fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

// delay with promise
export function sleeper(ms) {
  return function (x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms))
  }
}

// check if value is unique
export const unique = (value, index, self) => {
  return self.indexOf(value) === index
}

// see if an object is found in another array of objects
export function hasObject(recs, vals) {
  if (!recs) return false

  return recs.some(function (obj) {
    for (var x in obj) if (x in vals && obj[x] != vals[x]) return false
    return true
  })
}

// keep number counters within a range
export function clampRange(value, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

// Maps a value to a new range
export function map(value, start1, stop1, start2, stop2) {
  return ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2
}

// wrap incremental
export function wrap(index, length) {
  if (index < 0) index = length + (index % length)
  if (index >= length) return index % length
  return index
}

// sort ascending
export function sortAsc(arr, field) {
  return arr.sort(function (a, b) {
    if (a[field] > b[field]) return 1
    if (b[field] > a[field]) return -1
    return 0
  })
}

// sort descending
export function sortDesc(arr, field) {
  return arr.sort(function (a, b) {
    if (a[field] > b[field]) return -1
    if (b[field] > a[field]) return 1
    return 0
  })
}

export const CUSTOM_PRODUCTION = '(custom production)'

// get title based on variant options
export function formatOption({ name, value }) {
  if (!value) return false
  const label = value
    .toLowerCase()
    .replace(CUSTOM_PRODUCTION, '')
    .replace(' ', '')

  const suffix = value?.toLowerCase().indexOf(CUSTOM_PRODUCTION) > -1 ? '*' : ''

  switch (name) {
    case 'Size':
      // Skip if measurement is in inches
      if (label.indexOf('″') === -1)
        // Run if measurement is in cm
        return label.indexOf('a') > -1
          ? label.toUpperCase()
          : `${label} cm${suffix}`
    default:
      return label.substr(0, 1).toUpperCase().concat(label.substr(1))
  }
}

// convert cents to dollars, optional trailing zeros if round amount
export function centsToPrice(cents, trailing = false) {
  const price = cents / 100

  if (!trailing && price % 1 === 0) {
    return `€${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  } else {
    const parts = price.toFixed(2).split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `€${parts.join('.')}`
  }
}

export function getPageLink(link) {
  const { isHome } = link?.page || {}
  const params = `${link?.urlParams || ''}`
  if (isHome) return `/${params}`
  return `/${getPageSlugs(link?.page).join('/')}${params}`
}

export function getPageSlugs(page) {
  const { type, slug = '', category, slide, isCurated } = page || {}
  const cat = category?.slug
  if (!slug) return []
  switch (type) {
    case 'story':
      const path = ['explore', cat, slug]
      if (slide) return [...path, slide]
      return path
    case 'article':
      return ['articles', slug]
    case 'storyCategory':
      return ['explore', slug]
    case 'artist':
      return [type, slug]
    case 'product':
      return [cat, slug]
    case 'collection':
      if (cat) {
        // Collection with category
        return isCurated ? [slug] : [cat, slug]
      } else {
        // Collection index (without category)
        return [slug]
      }
    case 'category':
      // Categories index
      return [slug]
    default:
      // Pages
      return slug.split('/').filter((e) => e)
  }
}

export function getVariantTitle(variant, skip) {
  if (variant.options.length > 0) {
    const options = variant.options
      .filter((o) => o.name !== skip)
      .map((option) => formatOption(option))
    return options.join(' / ')
  }
  return variant
}

/*  ------------------------------ */
/*  Client helpers
/*  ------------------------------ */

export const Keys = {
  ENTER: 13,
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
}

export const isBrowser = typeof window !== 'undefined'

export const xsMax = 480
export const smMax = 768
export const mdMax = 940
export const lgMax = 1200
export const xlMax = 1600

export function useWindowSize() {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      bodyPadding: parseInt(
        window.getComputedStyle(document.body).paddingLeft,
        10
      ),
      sidebarWidth: parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--sidebar-width'
        ),
        10
      ),
      overlayWidth: parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          '--overlay-width'
        ),
        10
      ),
    }
  }

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    bodyPadding: 0,
    sidebarWidth: 0,
    overlayWidth: 0,
  })

  useEffect(() => {
    if (!isBrowser) return

    function handleResize() {
      setWindowSize(getSize())
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

// update multiple url params easily
export function useParams(fallback) {
  const router = useRouter()
  const currentPath = router.asPath.split('?')[0]
  const hasQuery = Object.keys(router.query).length
  let currentParams = fallback

  // if query params present, update the current parameters
  if (hasQuery) {
    currentParams = fallback.map((param) =>
      router.query[param.name]
        ? { ...param, value: router.query[param.name] }
        : param
    )
  }

  // update the query params on change
  const setCurrentParams = useCallback(
    (params) => {
      const urlParams = params
        .filter(
          (p) => p.value !== fallback.find((fb) => fb.name === p.name)?.value
        )
        .reduce((r, { name, value }) => ((r[name] = value?.split(',')), r), {})

      const qs = queryString.stringify(urlParams, {
        arrayFormat: 'comma',
      })

      router.replace(`${currentPath}${qs ? `?${qs}` : ''}`, undefined, {
        shallow: true,
      })
    },
    [router]
  )

  return [currentParams, setCurrentParams]
}

// use a Portal for overlays
export function InPortal({ id, children }) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) return null

  return ReactDOM.createPortal(children, document.querySelector(`#${id}`))
}

// restore previous scroll position after page change
export function useScrollRestoration(router, delay) {
  const restorePosition = useRef({})

  const saveScrollPosition = (url, pos) => {
    restorePosition.current = {
      ...restorePosition.current,
      [url]: pos,
    }
  }

  const updateScrollPosition = (url, restore, shouldRestore) => {
    const position = restore.current[url]

    // if we have a saved position and it's a history change, restore position, otherwise set to 0
    setTimeout(() => {
      requestAnimationFrame(() => {
        window.scrollTo({ top: position && shouldRestore ? position : 0 })
      })
    }, delay + 100)
  }

  useEffect(() => {
    let shouldScrollRestore = false
    window.history.scrollRestoration = 'manual'

    const onBeforeUnload = (event) => {
      saveScrollPosition(router.asPath, window.scrollY)
      delete event['returnValue']
    }

    const onRouteChangeStart = () => {
      saveScrollPosition(router.asPath, window.scrollY)
    }

    const onRouteChangeComplete = (url, { shallow }) => {
      // Bail if we're just changing URL parameters
      if (shallow) return

      updateScrollPosition(url, restorePosition, shouldScrollRestore)

      // reset if we should restore the scroll position
      shouldScrollRestore = false
    }

    // save scroll position on route change
    window.addEventListener('beforeunload', onBeforeUnload)
    Router.events.on('routeChangeStart', onRouteChangeStart)

    // restore scroll position after route change completes
    Router.events.on('routeChangeComplete', onRouteChangeComplete)

    // if it's a history change, set to restore scroll position to "true"
    Router.beforePopState((state) => {
      shouldScrollRestore = true
      state.options.scroll = false
      return true
    })

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      Router.events.off('routeChangeStart', onRouteChangeStart)
      Router.events.off('routeChangeComplete', onRouteChangeComplete)
      Router.beforePopState(() => true)
    }
  }, [])
}

/*  ------------------------------ */
/*  Image helpers
/*  ------------------------------ */

export function buildSrc(image, { width, height, format, quality = 65 } = {}) {
  if (!image) return null
  let imgSrc = imageBuilder.image(image)

  if (width) {
    imgSrc = imgSrc.width(Math.round(width))
  }

  if (height) {
    imgSrc = imgSrc.height(Math.round(height))
  }

  if (format) {
    imgSrc = imgSrc.format(format)
  }

  if (quality) {
    imgSrc = imgSrc.quality(quality)
  }

  return imgSrc.fit('max').auto('format').url()
}

export function buildFrameSrc(photos, options) {
  if (!photos) return null
  const { photo } =
    photos?.find((p) => p.forOption === 'Frame:Framepicker') || {}
  if (!photo) return null
  return buildSrc(photo, options)
}

export function buildShopifySrc(src, layout, windowSize) {
  const {
    width: _width,
    height: _height,
    sidebarWidth,
    overlayWidth,
  } = windowSize
  const vw = _width
  const vh = _height
  if (!vw | !vh) return src
  if (!layout) return src
  const splitSrc = src.split('.png')

  const DPR = isBrowser ? window?.devicePixelRatio : 1
  const isMd = vw <= mdMax
  const cols =
    isBrowser && document.body.classList.contains('is-collection') ? 3 : 4 || 4

  function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
  }

  let width, height
  switch (layout) {
    case 'product':
      height = isMd ? vh : vh * 0.75
      break
    case 'product-card':
      width = vw / (isMd ? 1.5 : cols)
      break
    case 'product-card-hover':
    case 'product-card-frames':
      width = vw / ((isMd ? 0.33 : 1) * cols)
      height = width
      break
    case 'product-card-frames-hover':
      width = (vw - sidebarWidth) / ((isMd ? 0.33 : 1) * cols)
      break
    case 'spotlight':
      width = vw / (isMd ? 1 : 2)
      height = width * 1.33
      break
    case 'spotlight-product':
      width = vw / (isMd ? 2 : 4)
      break
    case 'spotlight-lg':
      width = vw
      height = isMd ? vh : width / 2
      break
    case 'story':
      height = vh * 0.8
      width = height * 0.6
      break
    case 'story-card':
      width = isMd ? vw : (vw - sidebarWidth) / 4
      height = width * 1.33
      break
    case 'gallery-half':
      width = isMd ? vw : (vw - sidebarWidth) / 2
      height = width * 1.33
      break
    case 'gallery-full':
      width = vw - (isMd ? 0 : sidebarWidth)
      height = width * 0.66
      break
    case 'artist-overlay':
      width = overlayWidth
      height = overlayWidth * 0.5
      break
    case 'article-thumbnail':
      height = 160
      width = 151
      break
    default:
      width = isMd ? mdMax : 1440
      height = width * 1.33
      break
  }

  if (width) width = width * DPR
  if (height) height = height * DPR

  const resizedSrc = `${splitSrc[0]}_${Math.round(width)}x${Math.round(
    height || width
  )}.png${splitSrc[1]}`
  return resizedSrc
}

/*  ------------------------------ */
/*  Currency helpers
/*  ------------------------------ */

export const defaultCurrency = {
  currencyCode: 'EUR',
  countryCode: 'DE',
  prefix: '€',
  suffix: '',
  addVAT: false,
}

export const currencies = [
  defaultCurrency,
  {
    currencyCode: 'DKK',
    countryCode: 'DK',
    prefix: '',
    suffix: '',
    addVAT: false,
  },
  {
    currencyCode: 'SEK',
    countryCode: 'SE',
    prefix: '',
    suffix: '',
    addVAT: false,
  },
  {
    currencyCode: 'NOK',
    countryCode: 'NO',
    prefix: '',
    suffix: '',
    addVAT: false,
  },
  {
    currencyCode: 'USD',
    countryCode: 'US',
    prefix: '$',
    suffix: '',
    addVAT: true,
  },
  {
    currencyCode: 'GBP',
    countryCode: 'GB',
    prefix: '£',
    suffix: '',
    addVAT: false,
  },
]

export const getCurrency = (currencyCode) => {
  return (
    currencies.find((c) => c.currencyCode === currencyCode) || defaultCurrency
  )
}

/*  ------------------------------ */
/*  Store type detection
/*  ------------------------------ */
export const isPrimaryStore = () => {
  return process.env.SHOPIFY_STORE_ID === 'paper-collective-com'
}

export const isUSStore = () => {
  return process.env.SHOPIFY_STORE_ID === 'paper-collective-us'
}
